<template>
    <!-- FOOTER SECTION -->
    <div v-show="isShow" class="footer">

        <div class="container">

            <div class="row">
                <div class="col-sm-3 col-md-3">
                    <div class="footer-item">
                        <img src="../../assets/images/logo/logo.png" alt="logo bottom" class="logo-bottom">
                        <div class="spacer-30"></div>
                        <p>
                        Perumahan Sulfat Greenland C4,<br>
                        Kelurahan Pandanwangi,<br>
                        Kecamatan Blimbing,<br>
                        Kota Malang, Jawa Timur
                        </p>
                        <div class="footer-sosmed">
                            <a href="#" title="" target="_blank">
                                <div class="item">
                                    <i class="fa fa-facebook"></i>
                                </div>
                            </a>
                            <a href="#" title="" target="_blank">
                                <div class="item">
                                    <i class="fa fa-twitter"></i>
                                </div>
                            </a>
                            <a href="https://www.instagram.com/phyliapsychocenter/" title="Phylia Psychology and Training Center" target="_blank">
                                <div class="item">
                                    <i class="fa fa-instagram"></i>
                                </div>
                            </a>
                          <a href="#" title="" target="_blank">
                            <div class="item">
                              <i class="fa fa-pinterest"></i>
                            </div>
                          </a>
                        </div>
                    </div>
                </div>
              <div class="col-sm-3 col-md-3"></div>
              <div class="col-sm-6 col-md-6">
                <div class="footer-item">
                  <GmapMap
                      :center="{lat:-7.9646176, lng:112.6544832}"
                      :zoom="15"
                      map-type-id="terrain"
                      style="width: 100%; height: 300px"
                  >
                    <GmapMarker :position="google && new google.maps.LatLng(-7.9646176, 112.6544832)"
                                ref="myMarker"/>
                  </GmapMap>
                </div>
              </div>
              <!--                <div class="col-sm-3 col-md-3">-->
              <!--                    <div class="footer-item">-->
              <!--                      <div class="footer-title">-->
              <!--                        Recent Post-->
              <!--                      </div>-->
              <!--                      <ul :key="index" class="recent-post" v-for="index in 2">-->
              <!--                        <li><a href="#" title="">{{ data[index].title }}</a>-->
              <!--                          <span class="date"><i-->
              <!--                              class="fa fa-clock-o"></i>{{ formatDate(data[index].publish_date) }}</span></li>-->
              <!--                      </ul>-->
              <!--                    </div>-->
              <!--                </div>-->
              <!--                <div class="col-sm-3 col-md-3">-->
              <!--                    <div class="footer-item">-->
              <!--                        <div class="footer-title">-->
              <!--                            PAGES-->
              <!--                        </div>-->
              <!--                        <ul class="list">-->
              <!--                            <li><a href="about.html" title="">About</a></li>-->
              <!--                            <li><a href="stories.html" title="">Success Stories</a></li>-->
              <!--                            <li><a href="events.html" title="">Events</a></li>-->
              <!--                            <li><a href="news-grid.html" title="">Blog</a></li>-->
              <!--                            <li><a href="faq.html" title="">Faq</a></li>-->
              <!--                            <li><a href="contact.html" title="">Contact</a></li>-->
              <!--                        </ul>-->
              <!--                    </div>-->
              <!--                </div>-->
              <!--                 <div class="col-sm-3 col-md-3">-->
              <!--                     <div class="footer-item">-->
              <!--                         <div class="footer-title">-->
              <!--                             Subscribe-->
              <!--                         </div>-->
              <!--                         <p>Lit sed The Best in dolor sit amet consectetur adipisicing elit sedconsectetur adipisicing</p>-->
              <!--                         <form action="#" class="footer-subscribe">-->
              <!--                             <input type="email" name="EMAIL" class="form-control" placeholder="enter your email">-->
              <!--                             <input id="p_submit" type="submit" value="send">-->
              <!--                             <label for="p_submit"><i class="fa fa-envelope"></i></label>-->
              <!--                             <p>Get latest updates and offers.</p>-->
              <!--                         </form>-->
              <!--                     </div>-->
              <!--                 </div>-->
            </div>
        </div>

        <div class="fcopy">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12">
                        <p class="ftex">&copy; 2020 psikologi-online.com - All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import {gmapApi} from 'vue2-google-maps'

export default {
  name: "Footer",
  props: ['isShow'],
  data() {
    return {
      isLoading: true,
      data: null,
      links: null,
      meta: null,
      page: 1,
    }
  },
  computed: {
    google: gmapApi
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getData(this.page)
    },
    getData(page) {
      this.isLoading = true;
      this.$axios.get(`categories/recent-news/articles`, {
        pages: page
      }).then(res => {
        res.data.data;
        const response = res.data;
        this.data = response.data;
        this.links = response.links;
        this.meta = response.meta;
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('MMMM DD, YYYY')
      }
    },
  },
}
</script>

<style scoped>

</style>
